<template>
  <div class="my-10 center" v-if="activeLanguage === 'de'">
    <span>
      Durch das Fortfahren stimmst du unseren
      {{ isMobile ? '\n' : '' }}
      <a href="https://eventboxde.com/terms_service" target="_blank">Nutzungsbedingungen</a> zu und bestätigst,
      <br />
      dass du unsere <a href="https://eventboxde.com/privacy_policy" target="_blank">AGB + Datenschutzerklärung</a>
      {{ isMobile ? '\n' : '' }}
      gelesen hast, um zu erfahren,
      <br />
      wie wir deine Daten erheben, benutzen und leilen,
      <br />
      und unsere <a href="https://eventboxde.com/cookie_policy" target="_blank">Cookie-Richtlinie</a>, um zu erfahren,
      {{ isMobile ? '\n' : '' }}
      wie wir Cookies nutzen.
    </span>
  </div>
  <div class="my-10 center" v-else>
    <span>
      By continuing, you agree to our
      <a href="https://eventboxde.com/terms_service" target="_blank">Terms of Service</a>,
      {{ isMobile ? '\n' : '' }}
      and confirm that you have read our <a href="https://eventboxde.com/privacy_policy" target="_blank">Privacy Policy</a>
      <br />
      to learn how we collect, use and share your information
      {{ isMobile ? '\n' : '' }}
      and our <a href="https://eventboxde.com/terms_service" target="_blank">Cookie Policy</a> to learn how we use cookies.
    </span>
  </div>
</template>


<script>
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: 'TermsComponent',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    activeLanguage() {
      return i18nService.getActiveLanguage();
    }
  }
};
</script>